import React, { useContext } from 'react'
import { Link } from 'gatsby'
import { Container, ThemeContext } from 'components/common'
import Envelope from 'assets/about/envelope.svg'
import Phone from 'assets/about/phone.svg'
import Marker from 'assets/about/marker.svg'
import EnvelopeWhite from 'assets/about/envelope-white.svg'
import PhoneWhite from 'assets/about/phone-white.svg'
import MarkerWhite from 'assets/about/marker-white.svg'
import { Wrapper, Img, P, DetailsContainer, Text } from './styles'

export const Details = () => {
  const { theme } = useContext(ThemeContext)

  return (
    <Wrapper as={Container}>
      <Text theme={theme}>
        <p>
          Hey! I'm Elom Tsiagbey (pronounced Chi-Ag-Bay). I'm a techie at heart
          and easily gravitate towards anything that has a chip in it. (I have
          to pause here to give a shoutout to my mom for enduring the countless
          pieces of tech I broke as a kid)
        </p>
        <p>
          I've called Ottawa, Canada home since 2006 and graduated from Carleton
          University with Bachelor's degree in Computer Science and a minor in
          Math. I currently work as Software Developer for Shopify as part of the team helping make
          commerce better for everyone!
        </p>
        <p>
          My recent interest include giving talks on different subjects in the
          programming hemisphere. I believe I have the knack to break down
          complex topics into bite sizes for easier consumption. Not only does
          it reinforce my understanding but it forces me to constantly learn new
          things and I get to share it.
        </p>
        <p>
          Outside of work I enjoy podcasts and have a nice rotation on different
          subjects ranging from comedy to food and obviosuly tech. I love to
          cook and often dream of risking it all for a restaurant, but till
          then, I'll settle for cooking for my wife and son.
        </p>
        <p>
          I hope to use this medium to share snippets of code or articles of new
          things I learn and to also give product reviews of tech I play with.
          There will be some food and occasional ramblings. Pop in here from
          time to time; You know you want to. You can
          <a href="/contact"> contact </a> me as well
        </p>
        <p>Shalom!</p>
      </Text>
    </Wrapper>
  )
}
